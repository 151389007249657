//USER AUTHORIZED
export const USER_AUTHORIZED = 'USER_AUTHORIZED'




// CONTENT

export const USER_CONTENT_DATA = 'USER_CONTENT_DATA'

//PROFILE 
export const USER_PROFILE_DATA = 'USER_PROFILE_DATA'
export const USER_INFO_DETAILS = 'USER_INFO_DETAILS'
export const UPDATE_PROFILE_PICTUTRE = 'UPDATE_PROFILE_PICTUTRE'
export const VALIDATE_USERNAME = 'VALIDATE_USERNAME'
export const VERIFY_PASSWORD = 'VERIFY_PASSWORD'
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD'
export const GET_FOLLOWING_LIST = 'GET_FOLLOWING_LIST'


export const GENERATE_NEW_TOKEN = 'GENERATE_NEW_TOKEN'

//TEMPORARY_PASSWORD
export const TEMP_PASSWORD_DATA = 'TEMP_PASSWORD_DATA'

//CREATE POST
export const CREATE_POST = 'CREATE_POST'



//PROFILE DETAILS BASED ON EXTERNAL_ID
export const GET_PROFILE_DETAILS_BY_ID = 'GET_PROFILE_DETAILS_BY_ID'
export const UPDATE_USER_DETAILS_BY_ID = 'UPDATE_USER_DETAILS_BY_ID'
export const USER_PROFILE_DETAILS = 'USER_PROFILE_DETAILS'

// GETTING THE CHARITY NAMES
export const GET_CHARITY_NAMES = 'GET_CHARITY_NAMES'


// MODERATOR

//STORING USERS DETAILS WHO HAVE LOGGED IN
export const GET_TOKEN_DETAILS = 'GET_TOKEN_DETAILS'
export const LOG_IN = 'LOG_IN'
export const LOG_OUT = 'LOG_OUT'

