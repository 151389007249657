import { Link, Redirect, useHistory } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import { Card, Button, Container, Row, Image, Form, Alert, Col, InputGroup } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import axios from "axios";
import { Login, tokenDetails } from '../actions/auth.js'
import { useDispatch } from "react-redux";

import { userAuthorized } from "../actions/isAuthorized";

import LoginNavbar from './LoginNavbar';
import PasswordScreen from "./PasswordScreen.js";
// import Footer from "../CharityFooter/Footer";
//css imports
// import "../../assets/scss/Login/loginPage.scss";

//CSS imports
import '../assets/scss/loginpage.scss';


// import x from '../../assets/svg/x.svg';


const LoginPage = () => {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const inputRef = useRef();


  const tempPassword = useSelector((state) => state.tempPassword);
  // console.log(tempPassword.statusCode)

  useEffect(() => {
    if (tempPassword.statusCode !== 200) {
      setVisible(false);
    } else {
      setVisible(true);
    }
  }, [tempPassword.statusCode])

  const handleSubmit = async (e, email, password) => {
    e.preventDefault();

    const res = await dispatch(Login(email, password));
    if (res.statusCode === 200) {
      localStorage.setItem("accessToken", res.data.accessToken);
      localStorage.setItem("refreshToken", res.data.refreshToken);
      localStorage.setItem(
        "user_external_id",
        res.data.db_user_external_id
      );
      dispatch(tokenDetails(res.data.accessToken, res.data.refreshToken, res.data.db_user_external_id));
      // setIsLoading(false);
      dispatch(userAuthorized(true));
      history.push("/home")
    } else if (res.statusCode >= 400) {
      setError(true)
    }
  }
  return (<>
    { visible ?
      <section className="charity-login-container">
        <LoginNavbar />
        <Container className="charitylogin-page-container">
          <Row>
            <Col sm={12} className="login-page-contents">
              <Card className="card">
                <Card.Header className="header">Moderator Login
                     {/* <Link to="/" className="close">
                            <img src={x} alt="close-button" /></Link> */}
                </Card.Header>
                <Card.Body>
                  {/* <Form className="password-form" onSubmit={(e) => sendLink(e, Email)}> */}
                  <Form className="password-form" onSubmit={(e) => handleSubmit(e, email, password)} >
                    <Form.Group className="email">
                      <Form.Label className="password-form-label">Email address</Form.Label>
                      {/* <Form.Control size="lg" type="email" id="myInput" placeholder="Email address" onChange={e => setEmail(e.target.value)} value={Email} /> */}
                      <Form.Control size="lg" type="email" id="myInput" placeholder="Email address" onChange={(e) => setEmail(e.target.value)} required />
                    </Form.Group>
                    <Form.Group className="password">
                      <Form.Label className="password-form-label">Password</Form.Label>
                      <Form.Control size="lg" ref={inputRef} type="password" id="myInput" onChange={(e) => setPassword(e.target.value)} placeholder="Password" required />
                    </Form.Group>
                    <Button className="submit-btn" type="submit">Login</Button>
                    {/* <p className="footer-text">
                             New User?
                                <a href="/signup"><span className="ml-1"> Sign Up</span></a> here
                            </p> */}
                    {error && <p>Please enter valid credentials</p>}
                  </Form>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
        {/* <Footer /> */}
      </section>
      : <PasswordScreen />
    };
  </>);
};
export default LoginPage;


