import React, { useState } from "react";
import { Card } from "react-bootstrap";

// Slick slider imports
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//image imports
import whiteBrandLogo from "../assets/svg/white-brandLogo.svg";

const ContentSlider = ({ item }) => {
  const [imgCount, setImgCount] = useState(1);

  //-------------- Slick slider --------------//
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div>
      {/* <div className='image-count'>{item.content_url.length > 1 && imgCount + '/' + item.content_url.length}</div> */}
      <div className="image-count">
        {item.content_url.length > 1 &&
          imgCount + "/" + item.content_url.length}
      </div>

      <Slider
        {...settings}
        afterChange={(currentSlide) => {
          setImgCount(currentSlide + 1);
        }}
      >
        {
          item.content_url.map(media => {
            if (media.contentType === "image/jpeg" || media.contentType === "image/png") {
              return (
                <>
                  <Card.Img className="activity-image" src={media.signedUrl} />
                </>
              )

            } else {
              return (
                // <div className="activity-video-content-wrapper">
                <>
                  {/* <img src={whiteBrandLogo} alr="brand-logo" className="video-brand-logo" /> */}
                  <video className="Activitypage-contentvideo" src={media.signedUrl} controls autoPlay="autoplay" muted></video>
                </>
                // </div>
              )
            }
          })
        }
      </Slider>
    </div>
  );
};

export default ContentSlider;
