import axios from "axios";
import { BASEURL } from "../config";
import { LOG_IN,GET_TOKEN_DETAILS,LOG_OUT } from '../constants/action-types'
export const Signup = (email,username,phone,password) => (dispatch) => {
   return axios({
      url: `${BASEURL}/users`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        "user_email_address" : `${email}`,
        "username"           : `${username}`,
        "user_mobile_number" : `${phone}`,
        "user_password"      : `${password}`
      },
    })
      .then((res) =>{
        return res.data.response
      }
      )
      .catch((error) => {
        console.error(error)
        return error
      });
  };


  export const Login = (email,password) => (dispatch) => {
    return axios({
      url: `${BASEURL}/login/verify/password`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        "user_email_address" : `${email}`,
        "user_password"      : `${password}`
      },
    })
      .then((res) =>{
        return res.data.response
      }
     
        // dispatch({
        //   type: LOG_IN,
        //   payload: res,
        // }),
      )
      .catch((error) => {
        console.error(error)
        return error
      });
  };


  // export const storeUserDetails = (email,password) => (dispatch) => {(
  //  type: 
  // )}


  export const tokenDetails = (token, refreshToken, user_external_id) => ({
    type: GET_TOKEN_DETAILS,
    payload: {
        token,
        refreshToken,
        user_external_id
    }
})


export const logoutUser = () => ({
  type: LOG_OUT,
  payload:""
});
