import React, { useEffect } from 'react';
import ActivityPage from './ActivityPage';
import LoginNavbar from "./LoginNavbar"

import '../assets/scss/DashboardHome.scss'

const DashboardHome = () => {

    return (
        <>
            <div style={{ backgroundColor: "#f2f2f2" }}>
                <LoginNavbar />
                <ActivityPage />
            </div>
        </>
    )
}

export default DashboardHome 