import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";

import "../assets/scss/passwordScreenStyle.scss";

import { tempPassword } from '../actions/tempPassword';

// import brandLogo from "../../assets/svg/brandLogo.svg";

const PasswordScreen = (props) => {
  const [password, setPassword] = useState("");

  const dispatch = useDispatch();

  const handlePassword = (e) => {
    setPassword(e.target.value);
  }

  const handleSubmit = (e, password) => {
    e.preventDefault();
    dispatch(tempPassword(password));
  }

  return (
    <section className="password-screen">
      <Container>
        <Row>
          <Col sm={8} md={6} className="mx-auto">
            {/* <div className="mobileView">
              <img src={brandLogo} width="66" height="48" alt="brand-logo" />
              <p>Payment Successful</p>
              <p>Please close window to continue</p>
            </div> */}
            <Form onSubmit={(e) => handleSubmit(e, password)} className="tempForm">
              <Form.Group controlId="formBasicPassword">
                <Form.Control type="password" placeholder="Password" onChange={handlePassword} />
              </Form.Group>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default PasswordScreen;
