import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Card,
  Container,
  Row,
  Col,
  Image,
  Form,
  Button,
  Table
} from "react-bootstrap";

import ContentSlider from "./ContentSlider";
import useUserPosts from "../actions/customHooks/ContentsAPI";

import { reportDeactivateApi, reportActivateApi } from '../api/ReportPostAPI';
//css imports
import "../assets/scss/ActivityPage.scss";
import ToastMessage from "./ToastMessage";
// import '../../assets/scss/UserProfile/profile.scss';

/*end**********************/
const ActivityPage = () => {
  const getTokenDetailsReducer = useSelector(
    (state) => state.getTokenDetailsReducer
  );
  const token = getTokenDetailsReducer.token;
  const user_external_id = getTokenDetailsReducer.user_external_id;
  const [pageNo, setPageNo] = useState(1);

  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
    const [ reported,setReported] = useState([])
    const [ unreported,setUnreported] = useState([])
    const [contentId, setContentId] = useState("")
    const [reportDetails, setReportDetails] = useState([])
    const [showReportBtn,setShowReportBtn] = useState("")
  const observer = useRef();

  const { loading, error, contentsArray, hasMore } = useUserPosts(
    pageNo,
    getTokenDetailsReducer.token,
    getTokenDetailsReducer.user_external_id
  );
  // console.log({ contentsArray });
  // console.log({ loading });
  // console.log({ error });
  // console.log({ hasMore });


  const closeButton = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 6L6 18"
        stroke="#212121"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 6L18 18"
        stroke="#212121"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const handleClose = () => {
    setShowModal(false);
  }

  const lastElementInContent = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNo((prevpageNo) => prevpageNo + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading]
  );


  const handleDeactivation = async (content_external_id) => {
    const res = await reportDeactivateApi(token, user_external_id, content_external_id);
    // dispatch(reportDeactivateApi(token, external_Id, content_external_id));
    // console.log(res)
    if (res.response.statusCode === 200) {
      setShow(true)
      setShowReportBtn(content_external_id)
      let arr = [...reported]
      arr.push(content_external_id)
      setReported(arr)

      let arr2= [...unreported]
      arr2 = arr2.filter(id=> id !== content_external_id)
      setUnreported(arr2)
      setTimeout(() => {
        setShow(false)
        // window.location.reload(true);
      }, 4000);
    }

  }

  const handleActivation = async (content_external_id) => {
    const res = await reportActivateApi(token, user_external_id, content_external_id);
    // console.log(res)
    if (res.response.statusCode === 200) {
      setShow(true)
      setShowReportBtn(content_external_id)
      let arr = [...unreported]
      arr.push(content_external_id)
      setUnreported(arr)

      let arr2= [...reported]
      arr2 = arr2.filter(id=> id !== content_external_id)
      setReported(arr2)
      setTimeout(() => {
        setShow(false)
        
        // window.location.reload(true);
      }, 4000);
    }
    // dispatch(reportActivateApi(token, external_Id, content_external_id));
  }

   //On modal, disable scroll on background body
   const BodyStyle = () => {
    useEffect(() => {
      // document.body.classList.add(MODAL_OPEN_CLASS);
      document.getElementById("body").className = "bodyStyleModal";
      return () => {
        document.getElementById("body").className = " ";
      };
    }, []);
    return <></>;
  };

  const handleReport = (reportDetail) => {
    setShowModal(true)
    setReportDetails(reportDetail)
  }

  let styles = showModal
  ? { display: "block",overflow: "auto"}
  : { display: "none" };

  let activityCards = contentsArray.map((item, index) => {
    // console.log(contentsArray)
    // item.content_external_id
    let showReportDetailsBtn = (<></>)

    

    let idBtn = <span style={{color:"#2077b1"}} onClick={() => setContentId(item.content_external_id)}>Get Content Id</span>
    // console.log({reported})
    // console.log({unreported})
    let reportUnreport = (<></>)
    if(reported.some(el=>item.content_external_id === el) ){
      reportUnreport = <> { <span style={{color:"#2077b1"}} onClick={() => handleActivation(item.content_external_id)}>Unreport</span>} </>
    }
    else if(unreported.some(el=>item.content_external_id === el)) {
      reportUnreport =  <> { <span style={{color:"#2077b1"}} onClick={() => handleDeactivation(item.content_external_id)}>Report</span>} </>
    }
    else
    {
      reportUnreport =  (
        <>
        {item.content_report_count < 99 && <span style={{color:"#2077b1"}} onClick={() => handleDeactivation(item.content_external_id)}>Report</span>}
        {(item.content_report_count > 0 && item.content_report_count < 99) && <span style={{color:"#555"}}>This post is reported by {item.content_report_count} users</span> }
        {(item.content_report_count > 0)  && <span style={{color:"#2077b1"}} onClick={() => handleActivation(item.content_external_id)}>Unreport</span>} 
        </>
      )
    if((item.content_report_count > 0 && item.content_report_count < 20) )
    showReportDetailsBtn = <Button style={{width:'14rem',backgroundColor:"#2077B1",margin:"0 2rem 0.5rem 1rem"}} onClick={()=>handleReport(item.report_details)}>Get Report Details</Button>
    }
    if (contentsArray.length === index + 1) {
      return (
        <>
           <Card className="postcontent-card" key={item.content_external_id} ref={lastElementInContent}>
            {item.content_description ? (
              <>
                <Card.Body>
                  <Card.Text>{item.content_description}</Card.Text>
                </Card.Body>
              </>
            ) : null}

              {/*----- Multiple content slider -----*/}
            <div className="image-slider-container">
              <ContentSlider item={item} />
            </div>

            <div className="report-wrapper">
            {reportUnreport}
            {contentId === item.content_external_id ? contentId : idBtn }
            </div>
           {/* { (item.content_report_count > 0 && item.content_report_count < 10) && <Button style={{width:'14rem',backgroundColor:"#2077B1",margin:"0 2rem 0.5rem 1rem"}} onClick={()=>handleReport(item.report_details)}>Get Report Details</Button>} */}
           {showReportDetailsBtn}

          </Card>
        </>
      );
    } else {
      return (
        <>
          <Card className="postcontent-card" key={item.content_external_id}>
            {item.content_description ? (
              <>
                <Card.Body>
                  <Card.Text>{item.content_description}</Card.Text>
                </Card.Body>
              </>
            ) : null}

              {/*----- Multiple content slider -----*/}
            <div className="image-slider-container">
              <ContentSlider item={item} />
            </div>

            <div className="report-wrapper">
            {reportUnreport}
            {contentId === item.content_external_id ? contentId : idBtn }
            </div>
           {/* { (item.content_report_count > 0 && item.content_report_count < 10) && <Button style={{width:'14rem',backgroundColor:"#2077B1",margin:"0 2rem 0.5rem 1rem"}} onClick={()=>handleReport(item.report_details)}>Get Report Details</Button>} */}
           {showReportDetailsBtn}

          </Card>
          
        </>
      );
    }
  });

  const showMessage = () => {
    if (loading) {
      return (
        <div style={{ height: "50px", fontWeight: "700" }}>Please Wait...</div>
      );
    }
    if (error) {
      return (
        <div style={{ height: "50px", fontWeight: "700" }}>
          Something went wrong. Please try reloading the page to logging in
          again
        </div>
      );
    }
    if (!hasMore) {
      return <div style={{ height: "50px", fontWeight: "700" }}></div>;
    }
  };
  return (
    <>
      <Container className="mt-3">
        <Row>
          <Col className='col-8 mx-auto'>
            {activityCards}
          </Col>
        </Row>
      </Container>
      {showMessage()}
      {show ? <ToastMessage message="successfully submitted" /> : null}
      {showModal && <BodyStyle />}
            {showModal && <div className="modal-backdrop"></div>}
            <div id="createContentModal" style={{width:"80rem"}}>
            <div className="modal in report-modal-container" role="dialog" style={styles}>
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title">Report Details</h4>
                    <button type="button" onClick={handleClose} className="close">
                      {closeButton}
                    </button>
                  </div>
                  <div className="modal-body">
                  {reportDetails &&
                  <Table bordered hover>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Name</th>
                      <th>Report Type</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reportDetails.map((el,index)=>(
                      <tr>
                                  <td>{index+1}</td>
                                  <td>{el.username}</td>
                                  <td>{el.report_type_name}</td>
                                  <td>{el.report_description}</td>
                                </tr>
                    ) )}
                  </tbody>
</Table>
    }

              </div>
            </div>
          </div>
        
        </div>
      </div>
    </>
  );
};
export default ActivityPage;
