import { combineReducers } from 'redux'
import { LOG_OUT } from '../constants/action-types'


import UserAuthorizedReducer from './userAuthorized';
import TempPasswordDataReducer from './tempPassword';
import getTokenDetailsReducer from './tokenDetails'

const appReducer = combineReducers({
// const rootReducer = combineReducers({
    isUserAuthorized: UserAuthorizedReducer,
    tempPassword: TempPasswordDataReducer,
    getTokenDetailsReducer: getTokenDetailsReducer
})

const rootReducer = (state, action) => {
    if (action.type === LOG_OUT ) {
      state = undefined
    }
    return appReducer(state, action)
  }
  
  export default rootReducer;
  