import React, { useState } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

const JWTPrivateRoute = ({ component: Component, ...rest }) => {
    // const [show, setShow] = useState(false);
    // console.log('hello')
    const JWTtoken = useSelector(state => state.tempPassword.statusCode)
    // console.log(JWTtoken);
    let show;
    if (JWTtoken !== 200) {
        show = false
        // setShow(false)
    } else {
        show = true
        // setShow(true)
    }
    // console.log(show)
    return (
        <Route
            {...rest}
            render={props => show ? (<Component {...props} />) : (<Redirect to="/" />)}
        />
    )
}

export default JWTPrivateRoute