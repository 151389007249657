import axios from 'axios';
import { BASEURL } from '../config';

export const reportDeactivateApi = (token, user_external_id, content_external_id) => {
    return axios({
        url: `${BASEURL}/adminDashboard/deactivateContent`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${token}`,
            'userexternalid': user_external_id,
        },
        data: {
            "content_external_id": `${content_external_id}`,
        },
        // withCredentials: true,
    }).then(res => res.data)
        .catch(err => console.log(err))
}

export const reportActivateApi = (token, user_external_id, content_external_id) => {
    return axios({
        url: `${BASEURL}/adminDashboard/activateContent`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${token}`,
            'userexternalid': user_external_id,
        },
        data: {
            "content_external_id": `${content_external_id}`,
        },
        // withCredentials: true,
    }).then(res => res.data)
        .catch(err => console.log(err))
}