import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
//comment
import ScrollMemory from 'react-router-scroll-memory';

//Importing the components from the index file in components
import {
  LoginPage,
  // SignupPage,
  PageNotFound,
  ErrorBoundary,
  Home
  // CharityLogin,
} from "./components";

//Importing Other files
import JWTPrivateRoute from "./JWTPrivateRoute";
import store from "./store";
// import ContentNavbar from "./components/ContentNavbar/ContentNavbar";

function App() {
  return (
    <Provider store={store}>
      <ErrorBoundary>
        <Router>
          <ScrollMemory />
          <Switch>
            {/* <Route path='/' exact component={LandingPage} /> */}
            {/* <JWTPrivateRoute path='/login' exact component={LoginPage} /> */}
            {/* <JWTPrivateRoute path='/signup' exact component={SignUpPage} /> */}
            <Route path='/' exact component={LoginPage} />
            {/* <Route path='/signup' component={SignupPage} /> */}
            <JWTPrivateRoute path='/home' exact component={Home} />
            <JWTPrivateRoute component={PageNotFound} />
          </Switch>
        </Router>
      </ErrorBoundary>
    </Provider>
  );
}

export default App;
