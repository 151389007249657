import React from "react";

import { withRouter } from "react-router-dom";
import Logout from './Logout'
//image imports
import whiteBrandLogo from "../assets/svg/white-brandLogo.svg";

import blueBrandLogo from "../assets/svg/blue-brandLogo.svg";

//css imports
import { Navbar, Nav } from "react-bootstrap";
import "../assets/scss/CharityNavbar.scss";

const LogoutButton = () => (
  <Logout />
)
const LoginButton = () => {
  return (
    <Navbar.Collapse className="justify-content-end">
      <Nav>
        <Nav.Link
          href="/"
          id="learn-more-page-signup-button"
        >
          Login
        </Nav.Link>
      </Nav>
    </Navbar.Collapse>
  );
};

const SignupButton = () => {
  return (
    <Navbar.Collapse className="justify-content-end">
      <Nav>
        <Nav.Link
          href="/signup"
          id="learn-more-page-signup-button"
        >
          Sign Up
        </Nav.Link>
      </Nav>
    </Navbar.Collapse>
  );
};

const LoginNavbar = (props) => {
  const path = props.location.pathname; //gives current path

  return (
    <section className="learn-more-nav-wrapper">
      <Navbar className="learn-more-nav">
        <Navbar.Brand href="#home" className="brand-logo">
          <img src={blueBrandLogo} alr="brand-logo" />
        </Navbar.Brand>

        {/*the search bar is visible for learn more page  */}
        {(path !== "/" && path !== "/signup") && <LogoutButton />}
        {/* {path === "/" && <SignupButton />} */}
        {/* {path === "/signup" && <LoginButton />} */}
        {/* <LogoutButton /> */}
      </Navbar>
    </section>
  );
};

export default withRouter(LoginNavbar);
