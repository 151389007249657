import { useState, useEffect } from "react";
import { BASEURL } from "../../config";
import axios from "axios";

export default function useUserPosts(pageNo, token, external_Id) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [contentsArray, setContentsArray] = useState([]);
  const [hasMore, setHasMore] = useState(false);

  useEffect(() => {
    setLoading(true);
    setError(false);
    axios({
      method: "POST",
      url: `${BASEURL}/adminDashboard/getAllContents`,
      data: {
        pageNo: pageNo,
      },
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
        userexternalid: external_Id,
      },
      //   withCredentials: true,
    })
      .then((response) => {
        // console.log(response.data.response.data.pagination_info.has_next_page);
        setLoading(true);
        setContentsArray((prevContentsArray) => {
          if (response.data.response.statusCode === 200) {
            return [
              ...prevContentsArray,
              ...response.data.response.data.contents,
            ];
          } else {
            return [...prevContentsArray];
          }
        });
        setHasMore(response.data.response.data.pagination_info.has_next_page);
        setLoading(false);
      })
      .catch((e) => {
        setError(true);
      });
  }, [pageNo, token, external_Id]);
  return { loading, error, contentsArray, hasMore };
}
