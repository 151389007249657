import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
//File Imports
import { userAuthorized } from "../actions/isAuthorized";
import { Button } from "react-bootstrap";
// import "../assets/scss/Logout.scss";
import { logoutUser } from '../actions/auth'

const Logout = () => {
  const isAuthorized = useSelector(
    (state) => state.isUserAuthorized.isAuthorized
  );
  //Setting a logout variable in the state
  const [logout, setLogout] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  // Function which runs when the user clicks on the logout button
  const handleLogout = () => {
    if (isAuthorized === true) {
      localStorage.clear(); //clear data from localstorage
      dispatch(logoutUser())
      dispatch(userAuthorized(false));
      history.push("/");
    } else {
      history.push("/");
    }
  };


  const logoutIcon = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.9424 10.9638C20.9041 10.8723 20.8494 10.789 20.7796 10.7193L18.5303 8.46987C18.2371 8.17736 17.7631 8.17736 17.4698 8.46987C17.1766 8.76313 17.1766 9.2379 17.4698 9.53042L18.4396 10.5002H14.25C13.8353 10.5002 13.5 10.8362 13.5 11.2502C13.5 11.6643 13.8353 12.0003 14.25 12.0003H18.4395L17.4698 12.9701C17.1765 13.2633 17.1765 13.7381 17.4698 14.0306C17.616 14.1776 17.808 14.2504 18.0001 14.2504C18.1921 14.2504 18.3841 14.1776 18.5303 14.0306L20.7796 11.7812C20.8494 11.7122 20.9041 11.629 20.9424 11.5367C21.0181 11.3538 21.0181 11.1468 20.9424 10.9638Z"
        fill="#9E9E9E"
      />
      <path
        d="M15.7501 13.5006C15.3354 13.5006 15.0001 13.8366 15.0001 14.2506V18.0008H12.0001V6.00014C12.0001 5.66938 11.7826 5.37686 11.4653 5.28162L8.86054 4.50009H15.0001V8.2503C15.0001 8.66432 15.3354 9.00032 15.7501 9.00032C16.1649 9.00032 16.5001 8.66432 16.5001 8.2503V3.75006C16.5001 3.33601 16.1649 3 15.7501 3H3.75C3.723 3 3.69898 3.01125 3.67276 3.01424C3.6375 3.018 3.60526 3.02398 3.57151 3.03224C3.49276 3.05249 3.42149 3.08325 3.35477 3.12523C3.33828 3.13575 3.31803 3.13648 3.30228 3.14847C3.29623 3.15301 3.29398 3.16127 3.28797 3.16577C3.20623 3.23025 3.13799 3.309 3.08849 3.40277C3.07798 3.42302 3.07573 3.44478 3.0675 3.46577C3.04349 3.52277 3.01726 3.57828 3.00826 3.64128C3.0045 3.66378 3.01125 3.68477 3.01051 3.70654C3.00977 3.72155 3 3.73505 3 3.75003V18.7508C3 19.1086 3.25274 19.4161 3.60301 19.4859L11.1031 20.986C11.1518 20.9965 11.2013 21.001 11.2501 21.001C11.4218 21.001 11.5906 20.9417 11.7256 20.8307C11.8988 20.6882 12.0001 20.4759 12.0001 20.2509V19.5009H15.7501C16.1649 19.5009 16.5001 19.1649 16.5001 18.7509V14.2506C16.5001 13.8366 16.1649 13.5006 15.7501 13.5006Z"
        fill="#9E9E9E"
      />
    </svg>
  );

  return (
    // <div className="logout-button">
    <>

      <div className="justify-content-end navbar-collapse collapse" onClick={handleLogout}>
        {/* {logoutIcon} */}
        <Button style={{ marginRight: "64px" }} id="logout-button">Logout</Button>
      </div>
    </>
    // </div>
  );
};

export default Logout;
