import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
// import 'bootstrap/dist/css/bootstrap.css';
// Put any other imports below so that CSS from your components takes precedence over default styles.
import "./assets/scss/custom.scss";
import axios from "axios";
import axiosInstance from "./axios/axios";

// import createHistory from "history/createBrowserHistory";
// const myhistory = createHistory();

axios.interceptors.request.use(
  (request) => {
    // console.log(request)
    request.headers.Authorization = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
    request.headers.userexternalid = `${localStorage.getItem(
      "user_external_id"
    )}`;
    return request;
  },
  (error) => {
    // console.error(error);
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(null, (error) => {
  if (error.config && error.response?.status === 401) {
    // console.log("Token has expired!");
    // new Promise((resolve, reject) => {
    return axiosInstance({
      url: `/tokens`,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("refreshToken")}`,
        userexternalid: `${localStorage.getItem("user_external_id")}`,
      },
      // withCredentials: true,
    })
      .then((res) => {
        // console.log(res);
        localStorage.setItem("accessToken", res.data.response.data.accessToken);
        localStorage.setItem(
          "refreshToken",
          res.data.response.data.refreshToken
        );
        // console.log("The token has updated");

        error.config.headers.Authorization = `Bearer ${res.data.response.data.accessToken}`;

        return new Promise((resolve, reject) => {
          axiosInstance
            .request(error.config) // Repeat the initial request
            .then((result) => {
              resolve(result);
            })
            .catch((err) => {
              reject(err);
            });
        });
      })
      .catch((err) => {
        console.log(err);
        // console.log(err.response.data)
        // console.log(err.response.headers)
        //When the refresh token expires, log the user out
        // if (err.response.status === 401) {
        //   return (
        //     myhistory.replace('/'),
        //     window.location.reload(false),
        //     localStorage.clear()
        //   )
        // }
      });
    // })
  } else {
    return Promise.reject(error);
  }
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
