import axios from 'axios';
import { TEMP_PASSWORD_DATA } from "../constants/action-types";
// import { BASEURL } from "../config";

export const tempPassword = (password) => dispatch => {
    axios
        .post(`https://api.gopoolit.com/api/v1/temppassword`, { password })
        .then(tempPasswordToken => {
            // console.log(tempPasswordToken);
            dispatch({
                type: TEMP_PASSWORD_DATA,
                payload: tempPasswordToken.data.response
            })
        })
        .catch(err => console.log(err))
}